<template>
	<div class="container-fluid">
		<div class="row py-3 justify-content-center">
			<div class="col-12">
				<ul class="list-group mb-4 mt-4">
					<li
						v-for="player in banneds"
						:key="player.id"
						class="list-group-item"
					>
						<div class="moves-item">
							<router-link
								:to="{
									name: 'PlayerHome',
									params: {
										lang: lang,
										console: console,
										playerSlug: player.user.slug
									}
								}"
							>
								<img
									:src="player.user.avatar"
									:alt="player.user.nick"
									class="moves-item__avatar  mr-2"
								/>
							</router-link>
							<div class="moves-item__nick-reason">
								<router-link
									:to="{
										name: 'PlayerHome',
										params: {
											lang: lang,
											console: console,
											playerSlug: player.user.slug
										}
									}"
								>
									<span :class="{ 'text-muted': !player.status }">{{
										player.user.nick
									}}</span>
								</router-link>
								<span
									:class="{ 'text-muted': !player.status }"
									class="d-block mt-3"
									>{{ player.reason }}</span
								>
							</div>
							<div class="moves-item__details">
								<div class="text-muted">
									<span>{{ $t('bannedStart') }}: </span>
									<span>{{ player.date_start }}</span>
								</div>
								<div>
									<span>{{ $t('bannedEnd') }}: </span>
									<span>{{ player.date_end }}</span>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<b-pagination
					align="center"
					v-model="currentPage"
					:total-rows="rows"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		data: () => ({
			banneds: [],
			currentPage: 1,
			rows: 0,
			perPage: 20
		}),
		computed: {
			...mapGetters([
				'loading',
				'lang',
				'console',
				'team',
				'user',
				'tournamentFront'
			])
		},
		watch: {
			currentPage(val) {
				this.fetchData(val)
			}
		},
		mounted() {
			this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', 'banned')
			this.fetchData()
		},
		methods: {
			fetchData(page) {
				const slug = this.$route.params.divisionSlug
				const path = `${this.lang}/${this.console}/division/${slug}/banned`
				this.$axios
					.get(path, { params: { page: page || 1 } })
					.then((response) => {
						this.banneds = response.data.banned.data
						this.rows = response.data.banned.total
						this.perPage = response.data.banned.per_page
					})
			}
		}
	}
</script>

<style lang="scss">
	.moves-item__details {
		margin-left: auto;
		margin-right: 0 !important;
		text-align: right;
		min-width: 150px;
		span {
			line-height: 2;
		}
	}
	@media (max-width: 500px) {
		.list-group-item {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		.moves-item {
			.moves-item__details {
				div {
					span {
						&:first-of-type {
							display: none;
						}
					}
				}
			}
		}
	}
</style>
