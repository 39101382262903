var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row py-3 justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('ul',{staticClass:"list-group mb-4 mt-4"},_vm._l((_vm.banneds),function(player){return _c('li',{key:player.id,staticClass:"list-group-item"},[_c('div',{staticClass:"moves-item"},[_c('router-link',{attrs:{"to":{
								name: 'PlayerHome',
								params: {
									lang: _vm.lang,
									console: _vm.console,
									playerSlug: player.user.slug
								}
							}}},[_c('img',{staticClass:"moves-item__avatar mr-2",attrs:{"src":player.user.avatar,"alt":player.user.nick}})]),_c('div',{staticClass:"moves-item__nick-reason"},[_c('router-link',{attrs:{"to":{
									name: 'PlayerHome',
									params: {
										lang: _vm.lang,
										console: _vm.console,
										playerSlug: player.user.slug
									}
								}}},[_c('span',{class:{ 'text-muted': !player.status }},[_vm._v(_vm._s(player.user.nick))])]),_c('span',{staticClass:"d-block mt-3",class:{ 'text-muted': !player.status }},[_vm._v(_vm._s(player.reason))])],1),_c('div',{staticClass:"moves-item__details"},[_c('div',{staticClass:"text-muted"},[_c('span',[_vm._v(_vm._s(_vm.$t('bannedStart'))+": ")]),_c('span',[_vm._v(_vm._s(player.date_start))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('bannedEnd'))+": ")]),_c('span',[_vm._v(_vm._s(player.date_end))])])])],1)])}),0),_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }